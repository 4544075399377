import classNames from "classnames";
import { Navigate, useNavigate } from "react-router-dom";

import CommonCard from "../components/CommonCard";
import StepperCard from "../components/StepperCard";
import Button from "../components/NewButton";
import SelectBrandModal from "../components/project-stimuli/modals/SelectBrandModal";
import ValidationModal from "../components/project-stimuli/modals/ValidationModal";
import StimuliValidationModal from "../components/project-stimuli/modals/StimuliValidationModal";
import ConceptForm from "./conceptForm";
import { useProject } from "../contexts/new-project.context";
import { useError } from "../contexts/error.context";

import { httpRequest } from "../utils/http.util";

import {
  NewUploadIcon,
  StimuliDetailsDesignElementsIcon,
  StumiliDetailsLogoIcon,
  StumiliDetailsPackagingIcon,
} from "../assets/icons";
import { useState } from "react";

const UploadStimuli = () => {
  const navigate = useNavigate();
  const [showConceptForm, setShowConceptForm] = useState(false);

  const {
    projectId,
    logoFiles,
    packagingFiles,
    designElementsFiles,
    isStimuliProcessing,
    setLogoFiles,
    setPackagingFiles,
    setDesignElementsFiles,
    setIsStimuliFilesValidating,
    setStimuliValidationErrors,
    setIsShowValidationInput,
    isStimuliFilesValidating,
    isShowValidationInput,
    isSelectBrandModalOpen,
    stimuliValidationErrors,
    setIsSelectBrandModalOpen,
  } = useProject();
  const { handleAPIError } = useError();

  const validateBrandOccurence = () => {
    if (
      logoFiles.length < 1 &&
      packagingFiles.length < 1 &&
      designElementsFiles.length < 1
    )
      return true;

    if (stimuliValidationErrors.length === 0) {
      return true;
    }

    if (stimuliValidationErrors.some((item) => item.is_brand_name)) {
      return true;
    }

    const isBrandTrueCount = stimuliValidationErrors.reduce(
      (count, item) => (count + item.is_brand_name ? 1 : 0),
      0
    );

    const brandCount = stimuliValidationErrors.reduce(
      (count, item) =>
        count + (/\sbrand\s/i.test(` ${item.desc.toLowerCase()} `) ? 1 : 0),
      0
    );

    if (brandCount > 1 || isBrandTrueCount > 1) return false;
    else if (brandCount === 0) return false;
    else if (brandCount === 1) {
      const item = stimuliValidationErrors.find((item) =>
        /\sbrand\s/i.test(` ${item.desc.toLowerCase()} `)
      );

      if (item) {
        item.is_brand_name = true;

        setStimuliValidationErrors(
          stimuliValidationErrors.map((brandError) =>
            brandError.id === item.id ? item : brandError
          )
        );
      }

      return true;
    }
  };

  const handleStimuliFileSelect = async (e) => {
    const { name, files } = e.target;

    if (name === "designElementsFiles") {
      // Open form instead of file explorer
      setShowConceptForm(true);
      return; // Disable file upload for this category
    }

    const formData = new FormData();
    let fileCategory = "";

    if (name === "logoFiles") fileCategory = "logo";
    else if (name === "packagingFiles") fileCategory = "packaging_design";

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("image_files", file);
    }

    try {
      const fileUploadResponse = await httpRequest({
        method: "POST",
        url: `/projects/${projectId}/${fileCategory}/image_files`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        isAuthRequired: true,
      });

      if (name === "logoFiles")
        setLogoFiles((prev) => [...prev, ...fileUploadResponse.data]);
      else if (name === "packagingFiles")
        setPackagingFiles((prev) => [...prev, ...fileUploadResponse.data]);
    } catch (error) {
      handleAPIError(error);
    }
  };

  // const handleStimuliFileSelect = async (e) => {
  //   const { name, files } = e.target;
  //   const formData = new FormData();
  //   let fileCategory = "";

  //   if (name === "logoFiles") fileCategory = "logo";
  //   else if (name === "packagingFiles") fileCategory = "packaging_design";
  //   else if (name === "designElementsFiles") fileCategory = "design_element";

  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     formData.append("image_files", file);
  //   }

  //   try {
  //     const fileUploadResponse = await httpRequest({
  //       method: "POST",
  //       url: `/projects/${projectId}/${fileCategory}/image_files`,
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //       isAuthRequired: true,
  //     });


  //     if (name === "logoFiles")
  //       setLogoFiles((prev) => [...prev, ...fileUploadResponse.data]);
  //     else if (name === "packagingFiles")
  //       setPackagingFiles((prev) => [...prev, ...fileUploadResponse.data]);
  //     else if (name === "designElementsFiles")
  //       setShowConceptForm(true);
  //     return;
  //     // setDesignElementsFiles((prev) => [...prev, ...fileUploadResponse.data]);
  //   } catch (error) {
  //     handleAPIError(error);
  //   }
  // };

  const saveStimuliInputs = async () => {
    const stimuliInputsPayload = {
      logo: logoFiles.map((file) => ({
        name: file.name,
        image_hash: file.image_hash,
      })),
      packaging_design: packagingFiles.map((file) => ({
        name: file.name,
        image_hash: file.image_hash,
      })),
      design_element: designElementsFiles.map((file) => ({
        name: file.name,
        image_hash: file.image_hash,
      })),
      phrase_to_info: stimuliValidationErrors.reduce(
        (accumValue, brandError) => {
          const keyword = brandError.keyword;

          accumValue[keyword] = {
            is_brand_name: brandError.is_brand_name,
            desc: brandError.desc,
          };

          return accumValue;
        },
        {}
      ),
    };

    await httpRequest({
      method: "PUT",
      url: `/projects/${projectId}/stimuli_inputs`,
      data: stimuliInputsPayload,
      isAuthRequired: true,
    });
  };

  const submitStimuliDetails = async () => {
    const validationResult = validateBrandOccurence();

    try {
      setIsShowValidationInput(false);

      if (validationResult === true) {
        await saveStimuliInputs();
        navigate(`/${projectId}/select-persona`);
      } else {
        setIsSelectBrandModalOpen(true);
      }
    } catch (error) {
      handleAPIError(error);
    }
  };

  function handleChange(id, fileCategory, value) {
    if (fileCategory === "logoFiles") {
      setLogoFiles((prev) =>
        prev.map((val) => {
          if (val.image_hash === id) return { ...val, name: value };
          else return val;
        })
      );
    }
    if (fileCategory === "packagingFiles") {
      setPackagingFiles((prev) =>
        prev.map((val) => {
          if (val.image_hash === id) return { ...val, name: value };
          else return val;
        })
      );
    }
    if (fileCategory === "designElementsFiles") {
      setDesignElementsFiles((prev) =>
        prev.map((val) => {
          if (val.image_hash === id) return { ...val, name: value };
          else return val;
        })
      );
    }
  }

  function handleDeleteStimuliFile(id, fileCategory) {
    if (fileCategory === "logoFiles") {
      setLogoFiles((prev) => prev.filter((val) => val.image_hash !== id));
    }
    if (fileCategory === "packagingFiles") {
      setPackagingFiles((prev) => prev.filter((val) => val.image_hash !== id));
    }
    if (fileCategory === "designElementsFiles") {
      setDesignElementsFiles((prev) =>
        prev.filter((val) => val.image_hash !== id)
      );
    }
  }

  function validateUploads() {
    setIsStimuliFilesValidating(true);

    const validationPayload = {
      logo_image_hashes: logoFiles.map((file) => file.image_hash),
      packaging_design_image_hashes: packagingFiles.map(
        (file) => file.image_hash
      ),
      design_element_image_hashes: designElementsFiles.map(
        (file) => file.image_hash
      ),
    };

    const main = async () => {
      try {
        const validationResponse = await httpRequest({
          method: "POST",
          url: `/projects/${projectId}/get_validation_status`,
          isAuthRequired: true,
          data: validationPayload,
        });

        if (validationResponse.data?.validation_status) {
          clearInterval(intervalId);

          const { phrase_to_info } = validationResponse.data;
          const phraseToInfoEntries = Object.entries(phrase_to_info);

          setIsStimuliFilesValidating(false);

          const validatonResult = phraseToInfoEntries.map((phrase, index) => ({
            id: index + 1,
            keyword: phrase[0],
            desc: phrase[1]?.desc,
            is_brand_name: phrase[1]?.is_brand_name,
          }));

          if (validatonResult.length > 0) {
            setIsShowValidationInput(true);
            setStimuliValidationErrors(validatonResult);
          }
        }
      } catch (error) {
        setIsStimuliFilesValidating(false);
        clearInterval(intervalId);
        handleAPIError(error);
      }
    };

    main();
    const intervalId = setInterval(main, 2000);
  }

  if (!projectId) {
    return <Navigate to="/" replace />;
  }

  if (showConceptForm) {
    return <ConceptForm onClose={() => setShowConceptForm(false)} />; // Pass `onClose` callback to hide the form
  }


  return (
    <>
      <div className="flex-auto overflow-auto flex flex-col">
        <div className="hidden-scroll mx-auto flex-auto w-full lg:w-[854px] overflow-auto py-8 space-y-8 px-3">
          <div className="space-y-4">
            <div className="text-[32px]">
              <span className="inline-flex items-center gap-x-2 font-semibold text-[#7A3DAA]">
                Add your designs
                <img
                  src="/png/Outbox Tray.png"
                  alt="Add your stimuli icon"
                  className="w-8 h-8"
                />
              </span>

              <br />

              <span className="font-normal text-[#99A8C0]">
                Upload design images for analysis and recommendations
              </span>
            </div>

            <div className="flex items-center gap-x-1">
              <StepperCard
                isComplete
                step={1}
                label="Project Name"
                setStep={() => {}}
              />
              <div className="w-10 border-[1.5px] border-dashed border-[#E7EBF1]" />
              <StepperCard
                isActive
                step={2}
                label="Add your designs"
                setStep={() => {}}
              />
              <div className="w-10 border-[1.5px] border-dashed border-[#E7EBF1]" />
              <StepperCard
                step={3}
                label="Select AI Personas"
                setStep={() => {}}
              />
            </div>
          </div>

          <div className="flex flex-col gap-y-4">
            {/* LOGO */}
            <div>
              <div
                className={classNames(
                  "flex justify-between rounded-xl",
                  logoFiles.length > 0
                    ? "items-end bg-white"
                    : "p-5 items-center bg-[#F9F0FD]"
                )}
              >
                <div className="flex items-center space-x-4">
                  <div
                    className={classNames(
                      "w-16 h-16 flex justify-center items-center rounded-xl",
                      logoFiles.length > 0 ? "bg-[#F9F0FD]" : "bg-white"
                    )}
                  >
                    <StumiliDetailsLogoIcon width={32} height={32} />
                  </div>

                  <div>
                    <p className="mb-1 text-2xl font-semibold">Logo</p>

                    <p className="text-sm text-[#536787]">
                      Upload variants of the logo of your brand
                    </p>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="logoFiles"
                    className={classNames(
                      "p-3 w-full flex items-center justify-center gap-x-2 rounded",
                      logoFiles.length > 0
                        ? "text-[#AA48CB] bg-[#F1EAF6]"
                        : "text-white bg-[#AA48CB] hover:bg-[#77328E]",
                      isStimuliProcessing
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    )}
                  >
                    <NewUploadIcon
                      fill={logoFiles.length > 0 ? "#AA48CB" : "white"}
                    />

                    <span>
                      {logoFiles.length > 0 ? "Upload More" : "Upload"}
                    </span>
                  </label>

                  <input
                    type="file"
                    disabled={isStimuliProcessing}
                    accept="image/jpg,image/jpeg,image/png"
                    id="logoFiles"
                    name="logoFiles"
                    multiple={true}
                    onChange={handleStimuliFileSelect}
                    className="hidden"
                  />
                </div>
              </div>

              {logoFiles.length > 0 ? (
                <div className="mt-4 grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                  {logoFiles.map((file) => (
                    <CommonCard
                      key={file.image_hash}
                      file={file}
                      fileCategory="logoFiles"
                      handleChange={handleChange}
                      handleDelete={handleDeleteStimuliFile}
                    />
                  ))}
                </div>
              ) : null}
            </div>

            {logoFiles.length ? (
              <div className="my-4 border-[1.5px] border-[#E7EBF1]" />
            ) : null}

            {/* PACKAGING */}
            <div>
              <div
                className={classNames(
                  "flex justify-between rounded-xl",
                  packagingFiles.length > 0
                    ? "items-end bg-white"
                    : "p-5 items-center bg-[#F5F7EC]"
                )}
              >
                <div className="flex items-center space-x-4">
                  <div
                    className={classNames(
                      "w-16 h-16 flex justify-center items-center rounded-xl",
                      packagingFiles.length > 0 ? "bg-[#F5F7EC]" : "bg-white"
                    )}
                  >
                    <StumiliDetailsPackagingIcon width={32} height={32} />
                  </div>

                  <div>
                    <p className="mb-1 text-2xl font-semibold">Packaging</p>

                    <p className="text-sm text-[#536787]">
                      Upload variants of packaging designs of your product
                    </p>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="packagingFiles"
                    className={classNames(
                      "p-3 w-full flex items-center justify-center gap-x-2 rounded",
                      packagingFiles.length > 0
                        ? "text-[#89A318] bg-[#F5F7EC]"
                        : "text-white bg-[#89A318] hover:bg-[#607211]",
                      isStimuliProcessing
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    )}
                  >
                    <NewUploadIcon
                      fill={packagingFiles.length > 0 ? "#89A318" : "white"}
                    />

                    <span>
                      {packagingFiles.length > 0 ? "Upload More" : "Upload"}
                    </span>
                  </label>

                  <input
                    type="file"
                    disabled={isStimuliProcessing}
                    accept="image/jpg,image/jpeg,image/png"
                    id="packagingFiles"
                    name="packagingFiles"
                    multiple={true}
                    onChange={handleStimuliFileSelect}
                    className="hidden"
                  />
                </div>
              </div>
              {packagingFiles.length > 0 ? (
                <div className="mt-4 grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                  {packagingFiles.map((file) => (
                    <CommonCard
                      key={file.image_hash}
                      file={file}
                      fileCategory="packagingFiles"
                      handleChange={handleChange}
                      handleDelete={handleDeleteStimuliFile}
                    />
                  ))}
                </div>
              ) : null}
            </div>

            {packagingFiles.length ? (
              <div className="my-4 border-[1.5px] border-[#E7EBF1]" />
            ) : null}

            {/* DESIGN ELEMENTS */}
            <div>
              <div
                className={classNames(
                  "flex justify-between rounded-xl",
                  designElementsFiles.length > 0
                    ? "items-end bg-white"
                    : "p-5 items-center bg-[#EDF4FD]"
                )}
              >
                <div className="flex items-center space-x-4">
                  <div
                    className={classNames(
                      "w-16 h-16 flex justify-center items-center rounded-xl",
                      designElementsFiles.length > 0
                        ? "bg-[#EDF4FD]"
                        : "bg-white"
                    )}
                  >
                    <StimuliDetailsDesignElementsIcon width={32} height={32} />
                  </div>

                  <div>
                    <p className="mb-1 text-2xl font-semibold">Concept</p>

                    <p className="text-sm text-[#536787]">
                      Upload variants of a concept for your product
                    </p>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="designElementsFiles"
                    onClick={() => setShowConceptForm(true)}
                    className={classNames(
                      "p-3 w-full flex items-center justify-center gap-x-2 rounded",
                      designElementsFiles.length > 0
                        ? "text-[#1F70E1] bg-[#EDF4FD]"
                        : "text-white bg-[#1F70E1] hover:bg-[#164E9E]",
                      isStimuliProcessing
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    )}
                    disabled={isStimuliProcessing}
                  >
                    <NewUploadIcon
                      fill={
                        designElementsFiles.length > 0 ? "#1F70E1" : "white"
                      }
                    />

                    <span>
                      {"Upload"}
                    </span>
                  </label>

                  {/* <input
                    type="file"
                    disabled={isStimuliProcessing}
                    accept="image/jpg,image/jpeg,image/png"
                    id="designElementsFiles"
                    name="designElementsFiles"
                    multiple={true}
                    onChange={handleStimuliFileSelect}
                    className="hidden"
                  /> */}
                </div>
              </div>

              {designElementsFiles.length > 0 ? (
                <div className="mt-4 grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                  {designElementsFiles.map((file) => (
                    <CommonCard
                      key={file.image_hash}
                      file={file}
                      fileCategory="designElementsFiles"
                      handleChange={handleChange}
                      handleDelete={handleDeleteStimuliFile}
                    />
                  ))}
                </div>
              ) : null}
            </div>

            {/* <div className="text-right">
            <button
              onClick={validateUploads}
              disabled={isStimuliProcessing}
              className={classNames(
                "w-64 p-4 inline-flex justify-center items-center gap-x-2 rounded text-white bg-[#7A3DAA] hover:bg-[#5d327d]",
                { "cursor-not-allowed": isStimuliProcessing }
              )}
            >
              <InsightsStarsIcon fill="#ffffff" />
              <span className="text-sm font-medium">Generate Insights</span>
            </button>
          </div> */}
          </div>
        </div>

        {logoFiles.length ||
          packagingFiles.length ||
          designElementsFiles.length ? (
          <div className="py-3 mx-auto w-full lg:w-[854px] flex-none text-right px-3 space-x-3">
            <Button
              title="Back"
              variant="secondary"
              onClick={() => navigate("/")}
            />
            <Button
              title="Next"
              variant="primary"
              type="submit"
              onClick={validateUploads}
            />
          </div>
        ) : null}
      </div>

      {isStimuliFilesValidating ? <StimuliValidationModal /> : null}

      {isShowValidationInput ? (
        <ValidationModal submitStimuliDetails={submitStimuliDetails} />
      ) : null}

      {isSelectBrandModalOpen ? (
        <SelectBrandModal saveStimuliInputs={saveStimuliInputs} />
      ) : null}
    </>
  );
};

export default UploadStimuli;
